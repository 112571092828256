import * as constants from "../common/constants";
import { IDevice } from "../models/IDevice";
import { IGetGraphDataModel } from "../models/IGetGraphDataModel";
import { INegotiateSignalR } from "../models/INegotiateSignalR";
import { IUser } from "../models/IUser";

export const isAdmin = async (token: string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}is-admin`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp;
    return res.ok;
  } catch (error) {
    return error;
  }
};

export const resetPwd = async (token: string, email: string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}reset-password/${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp;
    return res.ok;
  } catch (error) {
    return error;
  }
};

export const getUsers = async (token: string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getUserByEmail = async (token: string, email: string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}userByEmail/${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getUserById = async (token: string, id: string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}userById/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const updateUser = async (token: string, user: IUser) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}user`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(user),
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    console.log("error");
  }
};

export const addSite = async (
  token: string,
  siteId: string,
  siteName: string
) => {
  try {
    const resp = await fetch(
      `${constants.apiBaseUrl}sites/add/${siteId}/${siteName}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getSites = async (token: string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}sites/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getSitesByUser = async (token: string, id: string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}sites-by-user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getAlarmCountForSites = async (token: string, siteIds: any) => {
  try {
    const resp = await fetch(
      `${constants.apiBaseUrl}alarm-count-for-sites/${siteIds}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getSiteById = async (
  token: string,
  siteId: string | undefined
) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}site/${siteId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getDevicesBySite = async (
  token: string,
  siteId: string | undefined
) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}devices/${siteId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getDevice = async (
  token: string,
  deviceId: string | undefined
) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}device/${deviceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const updateDevice = async (token: string, device: IDevice) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}device`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(device),
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    console.log("error");
  }
};

export const deleteDevice = async (token: string, id: string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}device/delete/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({}),
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const getAlarmCountForDevices = async (
  token: string,
  deviceIds: string
) => {
  try {
    const resp = await fetch(
      `${constants.apiBaseUrl}alarm-count-for-devices/${deviceIds}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getLatestReport = async (
  token: string,
  unitId: string | undefined
) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}report/latest/${unitId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getReportsByUnitId = async (token: string, unitId: string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}reports/${unitId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getGraphData = async (
  token: string,
  deviceId: string,
  startDate: string,
  endDate: string,
  useAgregatedData: boolean
) => {
  try {
    let post: IGetGraphDataModel = {
      unitId: deviceId,
      startDate: startDate,
      endDate: endDate,
      useAgregatedData,
    };

    const resp = await fetch(`${constants.apiBaseUrl}report/graphdata`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(post),
    });

    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getBrushData = async (token: string, unitId: number | string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}brushes/${unitId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getClient = async (token: string, clientId: string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}client/${clientId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    throw error;
  }
};

export const getClients = async (token: string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}clients`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    console.log("error");
  }
};

export const deleteClient = async (token: string, id: number) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}client/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteUser = async (token: string, email: string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}user/${email}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    console.log("error");
  }
};

export const deleteHmi = async (token: string, id: number) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}hmi/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    console.log("error");
  }
};

export const addUserToClient = async (token: string, user: IUser) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}add-user-to-client`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(user),
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    console.log("error");
  }
};

export const getAuth0Users = async (token: string) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}auth0Users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await resp.json();
    return res;
  } catch (error) {
    throw error;
  }
};

export const negotiateSignalR = async (): Promise<INegotiateSignalR> => {
  try {
    // const resp = await fetch(`http://localhost:7148/api/negotiate`);
    const resp = await fetch(
      `https://i-brush-realtime-updates-prd.azurewebsites.net/api/negotiate`
    );
    const res: INegotiateSignalR = await resp.json();
    return res;
  } catch (error) {
    throw error;
  }
};
